/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppCommunityListResponse } from '../models/AppCommunityListResponse';
import type { AppMode } from '../models/AppMode';
import type { CommDetectionJobDetailResponse } from '../models/CommDetectionJobDetailResponse';
import type { CommDetectionJobsResponse } from '../models/CommDetectionJobsResponse';
import type { CommunityDetectionRequest } from '../models/CommunityDetectionRequest';
import type { CommunityType } from '../models/CommunityType';
import type { CreateCommunityResponse } from '../models/CreateCommunityResponse';
import type { FilterDefinitionListResponse } from '../models/FilterDefinitionListResponse';
import type { FilterDistinctRequest } from '../models/FilterDistinctRequest';
import type { FilterListResponse } from '../models/FilterListResponse';
import type { FilterRequest } from '../models/FilterRequest';
import type { GetWarningResponse } from '../models/GetWarningResponse';
import type { JobCommunityListResponse } from '../models/JobCommunityListResponse';
import type { JobCommunitySummaryResponse } from '../models/JobCommunitySummaryResponse';
import type { JobSegmentListResponse } from '../models/JobSegmentListResponse';
import type { JobSegmentsHeaderResponse } from '../models/JobSegmentsHeaderResponse';
import type { OrderDefinitionListResponse } from '../models/OrderDefinitionListResponse';
import type { Platform } from '../models/Platform';
import type { PlotNameListResponse } from '../models/PlotNameListResponse';
import type { SegmentType } from '../models/SegmentType';
import type { TagFilter } from '../models/TagFilter';
import type { UpdateCommDecisionListRequest } from '../models/UpdateCommDecisionListRequest';
import type { UpdateCommDetectionJobsRequest } from '../models/UpdateCommDetectionJobsRequest';
import type { UpdateSegmentListRequest } from '../models/UpdateSegmentListRequest';
import type { UpdateShareCommunitiesResponse } from '../models/UpdateShareCommunitiesResponse';
import type { UpdateWarningRequest } from '../models/UpdateWarningRequest';
import type { UpsertJobSegmentsHeaderRequest } from '../models/UpsertJobSegmentsHeaderRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CommunityDetectionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Community Detection
     * @param requestBody 
     * @returns CreateCommunityResponse Successful Response
     * @throws ApiError
     */
    public createCommunityDetectionV1CommunityDetectionPost(
requestBody: CommunityDetectionRequest,
): CancelablePromise<CreateCommunityResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/community/detection/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Delete Community Detection Job
     * @param cdId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteCommunityDetectionJobV1CommunityDetectionCdIdDelete(
cdId: number,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/community/detection/{cd_id}',
            path: {
                'cd_id': cdId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update Community Detection Job
     * @param cdId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateCommunityDetectionJobV1CommunityDetectionCdIdPatch(
cdId: number,
requestBody: UpdateCommDetectionJobsRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/community/detection/{cd_id}',
            path: {
                'cd_id': cdId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Community Detection Jobs
     * @param appId 
     * @param appMode 
     * @param archived 
     * @param limit 
     * @returns CommDetectionJobsResponse Successful Response
     * @throws ApiError
     */
    public getCommunityDetectionJobsV1CommunityDetectionJobAllGet(
appId?: number,
appMode?: AppMode,
archived: boolean = false,
limit: number = 1000,
): CancelablePromise<CommDetectionJobsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/job/all',
            query: {
                'app_id': appId,
                'app_mode': appMode,
                'archived': archived,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Community Detection
     * @param cdId 
     * @returns CommDetectionJobDetailResponse Successful Response
     * @throws ApiError
     */
    public getCommunityDetectionV1CommunityDetectionJobCdIdGet(
cdId: number,
): CancelablePromise<CommDetectionJobDetailResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/job/{cd_id}',
            path: {
                'cd_id': cdId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Job Communities
     * @param cdId 
     * @param commType 
     * @returns JobCommunityListResponse Successful Response
     * @throws ApiError
     */
    public getJobCommunitiesV1CommunityDetectionJobCdIdCommunitiesGet(
cdId: number,
commType: CommunityType,
): CancelablePromise<JobCommunityListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/job/{cd_id}/communities',
            path: {
                'cd_id': cdId,
            },
            query: {
                'comm_type': commType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Job Communities Summary
     * @param cdId 
     * @returns JobCommunitySummaryResponse Successful Response
     * @throws ApiError
     */
    public getJobCommunitiesSummaryV1CommunityDetectionJobCdIdCommunitiesSummaryGet(
cdId: number,
): CancelablePromise<JobCommunitySummaryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/job/{cd_id}/communities/summary',
            path: {
                'cd_id': cdId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get App Communities
     * @param appId 
     * @param platform 
     * @returns AppCommunityListResponse Successful Response
     * @throws ApiError
     */
    public getAppCommunitiesV1CommunityDetectionAppIdCommunitiesGet(
appId: number,
platform: Platform,
): CancelablePromise<AppCommunityListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/{app_id}/communities',
            path: {
                'app_id': appId,
            },
            query: {
                'platform': platform,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Job Segments
     * @param cdId 
     * @param segmentType 
     * @param requestBody 
     * @param tagFilter 
     * @param itemsPerPage 
     * @param page 
     * @returns JobSegmentListResponse Successful Response
     * @throws ApiError
     */
    public getJobSegmentsV1CommunityDetectionJobCdIdSegmentsPost(
cdId: number,
segmentType: SegmentType,
requestBody: FilterRequest,
tagFilter?: TagFilter,
itemsPerPage: number = 50,
page: number = 1,
): CancelablePromise<JobSegmentListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/community/detection/job/{cd_id}/segments',
            path: {
                'cd_id': cdId,
            },
            query: {
                'segment_type': segmentType,
                'tag_filter': tagFilter,
                'items_per_page': itemsPerPage,
                'page': page,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Segments Filter Definition
     * @param cdId 
     * @param segmentType 
     * @returns FilterDefinitionListResponse Successful Response
     * @throws ApiError
     */
    public getSegmentsFilterDefinitionV1CommunityDetectionJobCdIdSegmentsFilterDefinitionGet(
cdId: number,
segmentType: SegmentType,
): CancelablePromise<FilterDefinitionListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/job/{cd_id}/segments/filter/definition',
            path: {
                'cd_id': cdId,
            },
            query: {
                'segment_type': segmentType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Segments Order Definition
     * @param cdId 
     * @param segmentType 
     * @returns OrderDefinitionListResponse Successful Response
     * @throws ApiError
     */
    public getSegmentsOrderDefinitionV1CommunityDetectionJobCdIdSegmentsOrderDefinitionGet(
cdId: number,
segmentType: SegmentType,
): CancelablePromise<OrderDefinitionListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/job/{cd_id}/segments/order/definition',
            path: {
                'cd_id': cdId,
            },
            query: {
                'segment_type': segmentType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Segments Filters
     * @param cdId 
     * @param filterDefinitionId 
     * @param segmentType 
     * @param requestBody 
     * @param tagFilter 
     * @returns FilterListResponse Successful Response
     * @throws ApiError
     */
    public getSegmentsFiltersV1CommunityDetectionJobCdIdSegmentsFilterDefinitionIdFiltersPost(
cdId: number,
filterDefinitionId: number,
segmentType: SegmentType,
requestBody: FilterDistinctRequest,
tagFilter?: TagFilter,
): CancelablePromise<FilterListResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/community/detection/job/{cd_id}/segments/{filter_definition_id}/filters',
            path: {
                'cd_id': cdId,
                'filter_definition_id': filterDefinitionId,
            },
            query: {
                'segment_type': segmentType,
                'tag_filter': tagFilter,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Job Segments Header
     * @param cdId 
     * @param segmentType 
     * @returns JobSegmentsHeaderResponse Successful Response
     * @throws ApiError
     */
    public getJobSegmentsHeaderV1CommunityDetectionJobCdIdSegmentsHeaderGet(
cdId: number,
segmentType: SegmentType,
): CancelablePromise<JobSegmentsHeaderResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/job/{cd_id}/segments/header',
            path: {
                'cd_id': cdId,
            },
            query: {
                'segment_type': segmentType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Upsert Job Segments Header
     * @param cdId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public upsertJobSegmentsHeaderV1CommunityDetectionJobCdIdSegmentsHeaderUpsertPost(
cdId: number,
requestBody: UpsertJobSegmentsHeaderRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/community/detection/job/{cd_id}/segments/header/upsert',
            path: {
                'cd_id': cdId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update Share Communities
     * @param cdId 
     * @param requestBody 
     * @returns UpdateShareCommunitiesResponse Successful Response
     * @throws ApiError
     */
    public updateShareCommunitiesV1CommunityDetectionCdIdCommunitySharePatch(
cdId: number,
requestBody: UpdateCommDecisionListRequest,
): CancelablePromise<UpdateShareCommunitiesResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/community/detection/{cd_id}/community/share',
            path: {
                'cd_id': cdId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get New Warnings
     * @param cdId 
     * @returns GetWarningResponse Successful Response
     * @throws ApiError
     */
    public getNewWarningsV1CommunityDetectionCdIdWarningsGet(
cdId: number,
): CancelablePromise<GetWarningResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/{cd_id}/warnings',
            path: {
                'cd_id': cdId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update Warnings
     * @param cdId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateWarningsV1CommunityDetectionCdIdWarningsUpdatePatch(
cdId: number,
requestBody: UpdateWarningRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/community/detection/{cd_id}/warnings/update',
            path: {
                'cd_id': cdId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Update Segments
     * @param cdId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateSegmentsV1CommunityDetectionCdIdSegmentsUpdatePatch(
cdId: number,
requestBody: UpdateSegmentListRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/community/detection/{cd_id}/segments/update',
            path: {
                'cd_id': cdId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

    /**
     * Get Plot Names
     * @param cdId 
     * @returns PlotNameListResponse Successful Response
     * @throws ApiError
     */
    public getPlotNamesV1CommunityDetectionJobCdIdPlotNamesGet(
cdId: number,
): CancelablePromise<PlotNameListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/community/detection/job/{cd_id}/plot/names',
            path: {
                'cd_id': cdId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                422: `Validation Error`,
                500: `Server Internal Error`,
                503: `Service Not Available`,
            },
        });
    }

}
