import React, {useEffect, useLayoutEffect, useReducer, useState} from 'react';
import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
//@ts-ignore
import {Link, useNavigate, useParams} from "react-router-dom";
//@ts-ignore
import alertStyle from "../../../general-styles/Alerts/alert-cards.module.scss";
//@ts-ignore
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";
//@ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
//@ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {getAppsNames, getCommunity} from "../../../../store/actions";
import '../community.scss'
import tableClasses from './table.module.scss';
import {AppMode} from "../../../../AUTO_GENERATED_TYPES";
import {SessionStorage} from "../../../../constants/sessionStorage";
import {useFirstMountState} from "react-use";
import ArchiveSwitch from "../Components/ArchiveSwitch/ArchiveSwitch";

interface CommunityTableFilters {
    appMode: AppMode,
    archiveMode: boolean,
    appName: number | null
}

const initialState: CommunityTableFilters = {
    appMode: AppMode.ALL,
    archiveMode: false,
    appName: null,
};

enum ActionType {
    SET_APP_MODE = "SET_APP_MODE",
    SET_APP_NAME = "SET_APP_NAME",
    TOGGLE_ARCHIVE = "TOGGLE_ARCHIVE"
}

interface SetAppModeAction {
    type: ActionType.SET_APP_MODE;
    payload: AppMode;
}

interface ToggleArchiveModeAction {
    type: ActionType.TOGGLE_ARCHIVE;
}

interface SetAppNameAction {
    type: ActionType.SET_APP_NAME;
    payload: number | null;
}

type Action = SetAppModeAction | SetAppNameAction | ToggleArchiveModeAction

function reducer(state: CommunityTableFilters, action: Action): CommunityTableFilters {
    switch (action.type) {
        case ActionType.SET_APP_MODE:
            return {...state, appMode: action.payload, appName: null};
        case ActionType.TOGGLE_ARCHIVE:
            return {...state, archiveMode: !state.archiveMode};
        case ActionType.SET_APP_NAME:
            return {...state, appName: action.payload};
        default:
            return state;
    }
}

const CommunityTable = () => {
    const params = useParams()
    const [{appMode, appName, archiveMode}, dispatchState] = useReducer(reducer, initialState);
    const [isAppSet,setIsAppSet] = useState(false)

    const [page, setPage] = useState(+(sessionStorage.getItem(SessionStorage.cDetectionPage) || 1))
    const [sizePerPage, setSizePerPage] = useState(+(sessionStorage.getItem(SessionStorage.cDetectionPerPage) || 25))

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {SearchBar} = Search;

    const {
        isPreloader
    } = useSelector((state: RootState) => ({
        isPreloader: state.Layout.isPreloader,
    }))

    useEffect(() => {
        if (params.appId && !isPreloader && !isAppSet) {
            setIsAppSet(true)
            dispatchState({
                type: ActionType.SET_APP_NAME,
                payload: Number(params.appId) ?? null
            })
        }
    }, [params, isPreloader,isAppSet])

    const {communityList, appNamesList} = useSelector((state: RootState) => ({
        communityList: state.community.communityList,
        appNamesList: state.community.appNamesList,
    }))

    const [hideColumn, setHideColumn] = useState(false)
    const firstRender = useFirstMountState()

    useEffect(() => {
        if (firstRender) {
            dispatch(getCommunity())
        } else {
            dispatch(getCommunity({appMode, appId: appName ?? undefined, archived: archiveMode}))
        }
    }, [appMode, appName, archiveMode])

    useEffect(() => {
        dispatch(getAppsNames(appMode))
    }, [appMode])

    const dateFormatter = (cell: any, row: any) => {
        let date = row.creation_time
            .replace(/[-]/g, '/')
            .replace(/[T]/g, '\u00A0');
        let jsDate = date.slice(0, 16);
        if (row.creation_time) {
            return (
                <span>
                    <p style={{marginBottom: 0}}>
                      {jsDate}
                    </p>
                </span>
            );
        }
    }


    const columns = [
        {
            dataField: 'community_detection_id',
            // hidden: true,
            text: 'Id',
            sort: true,
            headerStyle: (colum: any, colIndex: any) => {
                return {width: '35px', textAlign: 'center'};
            },
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'creation_time',
            text: 'Date',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: dateFormatter,
        },
        {
            dataField: 'run_name',
            text: 'Run Name',
            editable: false,
            sort: true,
            style: {
                // textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'customer_app_name',
            text: `${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}${'\u00A0'}App Name`,
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'customer_app_token',
            text: 'App Token',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'platform',
            text: 'Platform',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0',
            },
        },
        {
            dataField: 'status',
            text: 'Status',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            }
        },
        {
            dataField: 'cnt_total',
            text: '#Segments',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_comm_detection_pos',
            text: '#Pos',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_comm_detection_pos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'pct_comm_detection_pos',
            text: '%Add Comm DP',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cellContent: any, row: any) => (
                <>
                    <p>{row.pct_comm_detection_pos} %</p>
                </>
            )
        },
        {
            dataField: 'pct_added_pos',
            text: '%Added Pos',
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cellContent: any, row: any) => (
                <>
                    <p>{row.pct_added_pos} %</p>
                </>
            )
        },
        {
            dataField: 'cnt_users',
            text: '#Users',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_communities',
            text: '#Comm',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_communities.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_sessions',
            text: '#PosSessions',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_sessions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_additional_ids',
            text: '#Pos Additional Ids',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_additional_ids.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_pos_before',
            text: '#Pos Before',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_pos_before.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'cnt_added_pos',
            text: '#Added Poss',
            hidden: !hideColumn,
            editable: false,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cell: any, row: any) => (
                <>
                    <span>{row.cnt_added_pos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </>
            )
        },
        {
            dataField: 'pct_emu_comm_detection_pos',
            text: '%Pos Emulators',
            editable: false,
            hidden: !hideColumn,
            sort: true,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            formatter: (cellContent: any, row: any) => (
                <>
                    <p>{row.pct_emu_comm_detection_pos} %</p>
                </>
            )
        },
        {
            dataField: "actions",
            isDummyField: true,
            editable: false,
            style: {
                textAlign: 'center',
                borderBottom: '.5px solid #d0d0d0'
            },
            text: "Actions",
            formatter: (cellContent: any, row: any, rowIndex: any, extraData: any) => (
                <>
                    <div style={{position: "relative"}}>

                        <div className={'table_action'}>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip>Details</Tooltip>}
                                defaultShow={undefined}
                                delay={undefined}
                                flip={undefined}
                                onHide={undefined}
                                onToggle={undefined}
                                popperConfig={undefined}
                                show={undefined}
                                target={undefined}
                                trigger={undefined}>
                                <Link
                                    to="#"
                                    className="text-success"
                                    id='menuapp'
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr042.svg'
                                        className='svg-icon-edit svg-icon-2x'
                                    />
                                </Link>
                            </OverlayTrigger>

                        </div>
                    </div>
                </>
            ),
        },
    ];

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            navigate(`/community/job/${row.community_detection_id}`)
        },
    };

    useEffect(() => {
        sessionStorage.setItem(SessionStorage.cDetectionPage, String(page))
    }, [page])

    useEffect(() => {
        sessionStorage.setItem(SessionStorage.cDetectionPerPage, String(sizePerPage))
    }, [sizePerPage])

    const options = {
        page: page,
        sizePerPage: sizePerPage,
        paginationSize: communityList?.jobs?.length || 1,
        totalSize: communityList?.jobs?.length || 1,
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [25, 50],
        onSizePerPageChange: (value: number) => {
            setSizePerPage(value)
            localStorage.setItem(SessionStorage.cDetectionPage, String(1))
        },
        onPageChange: setPage,
    };

    const configBreadcrumbs: Array<PageLink> = [
        {
            title: 'Home /',
            path: '/dashboard/apps',
            isSeparator: false,
            isActive: false,
        },
    ]

    return (
        <div className={'comm_table'}>
            <PageTitle breadcrumbs={configBreadcrumbs}>Community Detection</PageTitle>
            {communityList ? (
                <>
                    <ToolkitProvider
                        keyField="community_detection_id"
                        data={communityList.jobs ? communityList.jobs : []}
                        columns={columns}
                        search
                        bootstrap4
                    >
                        {
                            (props: any) => (
                                <div style={{padding: '15px'}}>
                                    <div>
                                        <br/>
                                        <div className={'toolbar_table'}>
                                            <div>
                                                <SearchBar {...props.searchProps} />
                                            </div>


                                            <div className={'filter_toolbar'}>
                                                {/*APP MODE*/}
                                                <select onChange={(e) => {
                                                    dispatchState({
                                                        type: ActionType.SET_APP_MODE,
                                                        payload: e.target.value as AppMode
                                                    })
                                                    setPage(1)
                                                }}
                                                        defaultValue={AppMode.ALL}
                                                >
                                                    <option value={AppMode.ALL}>- App Mode -</option>
                                                    <option value={AppMode.LIVE}>Live</option>
                                                    <option value={AppMode.DEMO}>Demo</option>
                                                    <option value={AppMode.TESTING}>Testing</option>
                                                </select>
                                                {/* CHOOSE APP */}
                                                <select value={appName ?? ''} onChange={(e) => {
                                                    dispatchState({
                                                        type: ActionType.SET_APP_NAME,
                                                        payload: Number(e.target.value) ?? null
                                                    })
                                                    setPage(1)
                                                }}>
                                                    <option
                                                        value={''}>{appNamesList.apps?.length === 0 ? 'No Apps Found' : '-Choose App -'}</option>
                                                    {
                                                        appNamesList.apps?.map((mode: any) => (
                                                            <option key={mode.app_id}
                                                                    value={mode.app_id}>{mode.app_name}</option>
                                                        ))
                                                    }
                                                </select>
                                                {/*ARCHIVE MODE*/}
                                                <ArchiveSwitch checked={archiveMode} onChange={() => {
                                                    dispatchState({type: ActionType.TOGGLE_ARCHIVE})
                                                    setPage(1)
                                                }}/>

                                                <Button onClick={() => setHideColumn(prevState => !prevState)}>
                                                    <KTSVG
                                                        path='/media/icons/duotune/abstract/abs015.svg'
                                                        className='svg-icon-edit svg-icon-2'
                                                    />
                                                    {!hideColumn ? 'Show Data' : "Hide Data"}</Button>
                                            </div>
                                        </div>
                                        <br/>
                                    </div>
                                    {communityList?.jobs && !isPreloader ? <Row>
                                        <Col xl='12'
                                             className={`${tableClasses.table_wrapper} ${tableClasses['table_wrapper--community']}`}>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                keyField='community_detection_id'
                                                pagination={paginationFactory(options)}
                                                bordered={true}
                                                responsive
                                                stripped
                                                headerWrapperClasses={"header-class"}
                                                rowEvents={rowEvents}
                                            />
                                        </Col>
                                    </Row> : null
                                    }
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </>
            ) : (
                <>
                    <p>Loading....</p>
                </>
            )
            }
        </div>
    )
}

export default CommunityTable
