import classes from './AnnotationControls.module.scss'
import {Button} from "react-bootstrap";
import {KTSVG} from "../../../../../../_metronic/helpers";
import React, {useEffect, useState} from "react";
import {Card, Label} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import SegmentDetailsPagination from "../../../../Community/Components/SegmentDetailsPagination";
import {
    AnnotationClassesListResponse,
    AnnotationSegmentListResponse,
    FilterDefinitionListResponse,
    UpdateAnnotationListRequest
} from "../../../../../../AUTO_GENERATED_TYPES";
import TaggingTooltip from "../../../../Community/Components/TaggingTooltip";
import {useMutation, useQuery, useQueryClient} from "react-query";
import QueryKeys from "../../../../../../react-query/QueryKeys";
import {useNavigate, useParams} from "react-router-dom";
import {
    getAnnotationFilters, getAnnotationPlotNames,
    updateAnnotationTag
} from "../../../../../../_metronic/helpers/backend_helper";
import SweetAlertImport from "react-bootstrap-sweetalert";
import {toast} from "react-toastify";
import useAnnotation from "../../../../../../store/annotation/useAnnotation";
import AnnotationSort from "../AnnotationSort/AnnotationSort";
import AnnotationFilter from "../AnnotationFilter/AnnotationFilter";
import ROUTE_PATHS from '../../../../../routing/RoutePaths';

const SweetAlert = SweetAlertImport as any


interface AnnotationControlsProps {
    showDetails: boolean,
    toggleDetails: () => void,
}

const AnnotationControls: React.FC<AnnotationControlsProps> = ({toggleDetails, showDetails}) => {
    const [showSort, setShowSort] = useState(false)
    const [showFilter, setShowFilter] = useState(false)

    const {id, communityIdx} = useParams()
    const annotationId = Number(id)
    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const {
        data: {dataRequest, tagFilter, page, itemsPerPage, sortCount, filterCount, imageType},
        functions: {
            changePage,
            changeItemsPerPage,
            changeTagFilter,
            filterSAChange,
            resetSort,
            filterReset,
            changeImageType
        }
    } = useAnnotation()

    useEffect(() => {
        resetSort()
        filterReset()
        changeTagFilter('All')
    }, [])

    const filterListResponse = queryClient.getQueryData([QueryKeys.AnnotationFiltersDefinition, annotationId]) as FilterDefinitionListResponse | undefined
    const communityId = filterListResponse?.filters?.find(desc => desc.column_name === 'comm_idx')?.id

    const {data: annotationPlotNames} = useQuery([QueryKeys.AnnotationPlotNames,annotationId], () => getAnnotationPlotNames(annotationId),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            select: (data => data.plot_names),
            onSuccess: ((data: Record<string, string> | undefined) => {
                if (!data) {
                    return;
                }

                const firstOption = Object.entries(data).at(0)!
                changeImageType({value: firstOption[0], label: firstOption[0]})

            }),
        })

    const {data: communityList} = useQuery([QueryKeys.AnnotationFilters, annotationId, communityId, [], tagFilter], () => {
        if (!communityId) {
            return null
        }

        return getAnnotationFilters(annotationId, communityId, {filters: []}, tagFilter)
    }, {
        refetchOnWindowFocus: false,
        enabled: Boolean(communityId)
    })


    const communityOptions = communityList?.filter_list?.map(filter => <option key={filter.filter_name}
                                                                               value={filter.filter_name}>{`${filter.filter_name} (${filter.filter_counter})`}</option>)


    const annotationData: AnnotationSegmentListResponse | undefined = queryClient.getQueryData([QueryKeys.Annotation, QueryKeys.AnnotationJobData, annotationId, dataRequest, tagFilter, itemsPerPage, page])
    const paging = annotationData?.paging
    const classesOptions: AnnotationClassesListResponse | undefined = queryClient.getQueryData([QueryKeys.AnnotationJobClasses, annotationId])

    const [taggingModalContext, setTaggingModalContext] = useState({show: false, tag: '', tagName: ''})
    const openTaggingModal = (tag: string, tagName: string) => setTaggingModalContext({show: true, tag, tagName})
    const closeTaggingModal = () => setTaggingModalContext({show: false, tag: '', tagName: ''})


    const {mutate: tagAllHandler} = useMutation(({tag}: { tag: string, tagName: string }) => {
        const idList = annotationData?.segments?.map(seg => seg.id) ?? []
        const request: UpdateAnnotationListRequest = {update_list: [{segment_tag: tag, seg_id_list: idList}]}
        return updateAnnotationTag(annotationId, request)
    }, {
        onSettled: closeTaggingModal,
        onSuccess: (variable, variables) => {
            toast.success(`Changed all to - ${variables.tagName}`)
            queryClient.invalidateQueries([QueryKeys.Annotation])
        },
    })

    const refreshHandler = () => {
        queryClient.refetchQueries([QueryKeys.Annotation])
    }

    return <Card className={`${classes.wrapper} ${showDetails ? null : classes.wrapper__no_header}`}>
        <SweetAlert show={taggingModalContext.show}
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle={'danger'}
                    title={`Warning`}
                    onConfirm={() => {
                        tagAllHandler({tag: taggingModalContext.tag, tagName: taggingModalContext.tagName})
                    }
                    }
                    onCancel={closeTaggingModal}
        >
            <p>Are you sure you want to set all segments classes to <b>{taggingModalContext.tagName}</b></p>
        </SweetAlert>
        <select onChange={event => {
            if (event.target.value) {
                const [classCode, className] = event.target.value.split(',')
                openTaggingModal(classCode, className)
                event.target.value = ''
            }
        }}>
            <option value="">- Mark All -</option>
            {classesOptions?.classes.map((value) => <option key={value.class_code}
                                                            value={`${value.class_code},${value.class_name}`}>{value.class_name}</option>)}
        </select>
        <Button className={'position-relative'} onClick={(e) => {
            e.stopPropagation()
            setShowFilter(prevState => !prevState)
        }}>
            <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-edit svg-icon-2'
            />
            <span
                className={filterCount ? '' : 'seg_filter_none'}>Filter {filterCount ? `(${filterCount})` : ''}</span>
            <div>
                <AnnotationFilter isOpen={showFilter}
                                  onClose={() => setShowFilter(false)}/>
            </div>
            <div>
            </div>
        </Button>
        <select value={communityIdx} onChange={(event => {
            if (!communityList) return;

            filterSAChange(event.target.value, communityList)
            if (event.target.value) {
                navigate(`/annotation/${annotationId}/${event.target.value}`)
            } else {
                navigate(`/annotation/${annotationId}`)
            }

        })}>
            <option value="">Community Index</option>
            {communityOptions}
        </select>
        <select value={tagFilter} onChange={(e) => {
            changeTagFilter(e.target.value)
        }}>
            <option value={'All'}>All</option>
            <option value={'new'}>New</option>
            {classesOptions?.classes.map((value) => <option key={value.class_code}
                                                            value={value.class_code}>{value.class_name}</option>)}

        </select>
        <TaggingTooltip placement={'bottom'} tooltip={'Refresh Filter'}>
            <Button
                disabled={tagFilter === 'All'}
                style={{width: '40px', paddingLeft: '9px', paddingTop: '9px', position: "relative"}}
                onClick={refreshHandler}>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr029.svg'
                    className='svg-icon-edit svg-icon-1'
                />
            </Button>
        </TaggingTooltip>
        <Button className={'position-relative'} onClick={(e) => {
            e.stopPropagation()
            setShowSort(prevState => !prevState)
            setShowFilter(false)
        }}>
            <KTSVG
                path='/media/icons/duotune/general/gen032.svg'
                className='svg-icon-edit svg-icon-2'
            />
            <span className={'seg_filter_none'}>Sort {Boolean(sortCount) &&
                <span>({sortCount})</span>}</span>
            <AnnotationSort isOpen={showSort} setIsOpen={setShowSort}/>
        </Button>
        <Button onClick={toggleDetails}>
            <KTSVG
                path='/media/icons/duotune/arrows/arr032.svg'
                className='svg-icon-edit svg-icon-2'
            />
            Details
        </Button>
        <select value={imageType.value} style={{maxWidth: '200px'}}
                onChange={(event => changeImageType({
                    value: event.target.value,
                    label: event.target.value
                }))}>
            <option value={''}>- Plot Type -</option>
            {annotationPlotNames && Object.keys(annotationPlotNames).map((key, index) => <option key={index}
                                                                                                 value={key}>{annotationPlotNames[key]}</option>)}
        </select>

        <div className={classes.pagination}>
            {paging?.total_items ? <>
                <Label style={{marginBottom: 0}}>Per Page</Label>
                <CreatableSelect value={{
                    value: paging?.items_per_page,
                    label: `${paging?.items_per_page} (of ${paging?.total_items})`
                }}
                                 onChange={(e) => {
                                     if (e) {
                                         changeItemsPerPage(e.value)
                                     }
                                 }}
                                 options={[10, 25, 50, 100, 500, 1000].map(num => ({
                                     value: num,
                                     label: `${num} (of ${paging?.total_items})`
                                 }))}
                                 formatCreateLabel={(inputValue: string) => `${inputValue} ${paging?.total_items ? `(of ${paging.total_items})` : ''}`}
                                 createOptionPosition={'first'}
                                 isValidNewOption={inputValue => !isNaN(parseInt(inputValue))}

                />

                <SegmentDetailsPagination currentPage={page ?? 1} pageCount={paging?.num_of_pages ?? 1}
                                          handlePaginate={(({selected}) => changePage(selected))}/>
            </> : null}

        </div>
    </Card>
}

export default AnnotationControls
