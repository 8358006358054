import {CommunityTypes} from "../../types/StoreTypes";
import {CommunityActions, CommunityState} from "../../types/CommunityTypes";

const initialState: CommunityState = {
    loading: false,
    errorMsg: '',
    successMsg: '',
    communityList: [],
    communityJob: [],
    communityJobCommList: [],
    communityJobGeneral: [],
    appNamesList: [],
    segmentHeader: [],
    segmentData: [],
    warningsList: [],
    deployResponse: [],
    preDeployWarning: [],
    summaryTable: [],
    filterList: [],
    advancedFilterList: [],
    tag: '',
    result: false,
    typeOfResult: ''
}

const community = (state = initialState, action: CommunityActions) => {
    switch (action.type) {
        case CommunityTypes.GET_ALL_COMMUNITY:
            state = {
                ...state,
            }
            break
        case CommunityTypes.GET_ALL_COMMUNITY_SUCCESS:
            state = {
                ...state,
                communityList: action.payload
            }
            break
        case CommunityTypes.GET_ALL_COMMUNITY_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.GET_COMMUNITY_JOB_SUCCESS:
            state = {
                ...state,
                communityJob: action.payload
            }
            break
        case CommunityTypes.GET_COMMUNITY_JOB_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.GET_COMMUNITY_JOB_COMM_SUCCESS:
            state = {
                ...state,
                communityJobCommList: action.payload.communities,
                communityJobGeneral: action.payload
            }
            break
        case CommunityTypes.GET_COMMUNITY_JOB_COMM_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.GET_APPS_NAMES_SUCCESS:
            state = {
                ...state,
                appNamesList: action.payload
            }
            break
        case CommunityTypes.GET_APPS_NAMES_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.GET_SEGMENT_HEADER_SUCCESS:
            state = {
                ...state,
                segmentHeader: action.payload
            }
            break
        case CommunityTypes.GET_SEGMENT_HEADER_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.GET_SEGMENT_DATA_SUCCESS:
            state = {
                ...state,
                segmentData: action.payload
            }
            break
        case CommunityTypes.GET_SEGMENT_DATA_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.PATCH_DECISION_COMM_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case CommunityTypes.PATCH_DECISION_COMM_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.PATCH_COMM_DETAILS_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case CommunityTypes.PATCH_COMM_DETAILS_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.PATCH_SEG_DETAILS_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case CommunityTypes.PATCH_SEG_DETAILS_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.GET_WARNINGS_SUCCESS:
            state = {
                ...state,
                warningsList: action.payload
            }
            break
        case CommunityTypes.GET_WARNINGS_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.UPDATE_WARNINGS_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg
            }
            break
        case CommunityTypes.UPDATE_WARNINGS_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.PRE_DEPLOY_COMM_SUCCESS:
            state = {
                ...state,
                preDeployWarning: action.payload
            }
            break
        case CommunityTypes.PRE_DEPLOY_COMM_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.DEPLOY_COMM_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg,
                deployResponse: action.payload.response
            }
            break
        case CommunityTypes.DEPLOY_COMM_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.SEGMENT_TAGGING_SUCCESS:
            state = {
                ...state,
                result: action.payload.result,
                successMsg: action.payload.msg,
                typeOfResult: action.payload.type
            }
            break
        case CommunityTypes.SEGMENT_TAGGING_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                typeOfResult: action.payload.type
            }
            break
        case CommunityTypes.GET_SUMMARY_TABLE_SUCCESS:
            state = {
                ...state,
                summaryTable: action.payload
            }
            break
        case CommunityTypes.GET_SUMMARY_TABLE_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.GET_SEGMENTS_FILTERS_SUCCESS:
            state = {
                ...state,
                filterList: action.payload,
                advancedFilterList: action.payload
            }
            break
        case CommunityTypes.GET_SEGMENTS_FILTERS_FAIL:
            state = {
                ...state,
                result: action.payload.result,
                errorMsg: action.payload.msg
            }
            break
        case CommunityTypes.RESET:
            state = initialState
            break
        default:
            state = {...state}
            break
    }
    return state
}

export default community
