import {CommunityTypes} from "../../types/StoreTypes";
import {
    DeployComm,
    DeployCommSuccess,
    GetAppsNames,
    GetAppsNamesFail,
    GetAppsNamesSuccess,
    GetCommunity,
    GetCommunityFail,
    GetCommunityJob,
    GetCommunityJobComm,
    GetCommunityJobCommFail,
    GetCommunityJobCommSuccess,
    GetCommunityJobFail,
    GetCommunityJobSuccess,
    GetCommunitySuccess,
    GetSegments,
    GetSegmentsData,
    GetSegmentsDataFail,
    GetSegmentsDataSuccess,
    GetSegmentsFail,
    GetSegmentsFilters,
    GetSegmentsFiltersSuccess,
    GetSegmentsSuccess,
    GetSummaryTable,
    GetSummaryTableSuccess,
    GetWarnings,
    GetWarningsSuccess,
    PatchCommDetails,
    PatchCommDetailsFail,
    PatchCommDetailsSuccess,
    PatchDecisionComm,
    PatchDecisionCommFail,
    PatchDecisionCommSuccess,
    PatchSegDetails,
    PatchSegDetailsFail,
    PatchSegDetailsSuccess,
    PreDeployComm,
    PreDeployCommSuccess,
    ResetStore,
    SegmentTagging,
    SegmentTaggingFail,
    SegmentTaggingSuccess,
    UpdateWarnings,
    UpdateWarningsSuccess
} from "../../types/CommunityTypes";
import {
    AppMode, CommunityDetectionStatus,
    CommunityType,
    FilterRequest,
    JobCommunityListResponse,
    SegmentType,
    TagFilter,
    UpdateCommDecisionListRequest,
    UpdateCommDetectionJobsRequest,
    UpdateSegmentListRequest,
    UpdateWarningRequest,
    UpsertJobSegmentsHeaderRequest
} from "../../AUTO_GENERATED_TYPES";

export const getCommunity = ( communityRequest ?: { appId?: number | undefined, appMode?: AppMode | undefined, archived?: boolean, limit?: number }): GetCommunity => ({
    type: CommunityTypes.GET_ALL_COMMUNITY,
    payload: communityRequest
})
export const getCommunitySuccess = (list: string): GetCommunitySuccess => ({
    type: CommunityTypes.GET_ALL_COMMUNITY_SUCCESS,
    payload: list
})
export const getCommunityFail = (result: boolean, msg: string): GetCommunityFail => ({
    type: CommunityTypes.GET_ALL_COMMUNITY_FAIL,
    payload: {result, msg}
})
export const getCommunityJob = (jobId: number): GetCommunityJob => ({
    type: CommunityTypes.GET_COMMUNITY_JOB,
    payload: jobId
})
export const getCommunityJobSuccess = (list: string): GetCommunityJobSuccess => ({
    type: CommunityTypes.GET_COMMUNITY_JOB_SUCCESS,
    payload: list
})
export const getCommunityJobFail = (result: boolean, msg: string): GetCommunityJobFail => ({
    type: CommunityTypes.GET_COMMUNITY_JOB_FAIL,
    payload: {result, msg}
})
export const getCommunityJobComm = (jobId: number, type: CommunityType): GetCommunityJobComm => ({
    type: CommunityTypes.GET_COMMUNITY_JOB_COMM,
    payload: {jobId, type}
})
export const getCommunityJobCommSuccess = (list: JobCommunityListResponse): GetCommunityJobCommSuccess => ({
    type: CommunityTypes.GET_COMMUNITY_JOB_COMM_SUCCESS,
    payload: list
})
export const getCommunityJobCommFail = (result: boolean, msg: string): GetCommunityJobCommFail => ({
    type: CommunityTypes.GET_COMMUNITY_JOB_COMM_FAIL,
    payload: {result, msg}
})
export const getAppsNames = (appMode: AppMode): GetAppsNames => ({
    type: CommunityTypes.GET_APPS_NAMES,
    payload: appMode
})
export const getAppsNamesSuccess = (list: string): GetAppsNamesSuccess => ({
    type: CommunityTypes.GET_APPS_NAMES_SUCCESS,
    payload: list
})
export const getAppsNamesFail = (result: boolean, msg: string): GetAppsNamesFail => ({
    type: CommunityTypes.GET_APPS_NAMES_FAIL,
    payload: {result, msg}
})
export const getSegmentHeader = (cdId: number, segType: SegmentType): GetSegments => ({
    type: CommunityTypes.GET_SEGMENT_HEADER,
    payload: {cdId, segType}
})
export const getSegmentHeaderSuccess = (list: string): GetSegmentsSuccess => ({
    type: CommunityTypes.GET_SEGMENT_HEADER_SUCCESS,
    payload: list
})
export const getSegmentHeaderFail = (result: boolean, msg: string): GetSegmentsFail => ({
    type: CommunityTypes.GET_SEGMENT_HEADER_FAIL,
    payload: {result, msg}
})
export const getSegmentData = (cdId: number, segType: SegmentType, body: FilterRequest | null, itemPp: number, page: number, tag?: TagFilter): GetSegmentsData => ({
    type: CommunityTypes.GET_SEGMENT_DATA,
    payload: {cdId, segType, body, itemPp, page, tag}
})
export const getSegmentDataSuccess = (list: string): GetSegmentsDataSuccess => ({
    type: CommunityTypes.GET_SEGMENT_DATA_SUCCESS,
    payload: list
})
export const getSegmentDataFail = (result: boolean, msg: string): GetSegmentsDataFail => ({
    type: CommunityTypes.GET_SEGMENT_DATA_FAIL,
    payload: {result, msg}
})
export const updateDecision = (cdId: number, data: UpdateCommDecisionListRequest): PatchDecisionComm => ({
    type: CommunityTypes.PATCH_DECISION_COMM,
    payload: {cdId, data}
})
export const updateDecisionSuccess = (result: boolean, msg: string): PatchDecisionCommSuccess => ({
    type: CommunityTypes.PATCH_DECISION_COMM_SUCCESS,
    payload: {result, msg}
})
export const updateDecisionFail = (result: boolean, msg: string): PatchDecisionCommFail => ({
    type: CommunityTypes.PATCH_DECISION_COMM_FAIL,
    payload: {result, msg}
})
export const updateCommDetails = (cdId: number, data: UpdateCommDetectionJobsRequest): PatchCommDetails => ({
    type: CommunityTypes.PATCH_COMM_DETAILS,
    payload: {cdId, data}
})
export const updateCommDetailsSuccess = (result: boolean, msg: string): PatchCommDetailsSuccess => ({
    type: CommunityTypes.PATCH_COMM_DETAILS_SUCCESS,
    payload: {result, msg}
})
export const updateCommDetailsFail = (result: boolean, msg: string): PatchCommDetailsFail => ({
    type: CommunityTypes.PATCH_COMM_DETAILS_FAIL,
    payload: {result, msg}
})
export const patchSegDetails = (cdId: number, data: UpsertJobSegmentsHeaderRequest): PatchSegDetails => ({
    type: CommunityTypes.PATCH_SEG_DETAILS,
    payload: {cdId, data}
})
export const patchSegDetailsSuccess = (result: boolean, msg: string): PatchSegDetailsSuccess => ({
    type: CommunityTypes.PATCH_SEG_DETAILS_SUCCESS,
    payload: {result, msg}
})
export const patchSegDetailsFail = (result: boolean, msg: string): PatchSegDetailsFail => ({
    type: CommunityTypes.PATCH_SEG_DETAILS_FAIL,
    payload: {result, msg}
})
export const getWarnings = (cdId: number): GetWarnings => ({
    type: CommunityTypes.GET_WARNINGS,
    payload: cdId
})
export const getWarningsSuccess = (warningList: any): GetWarningsSuccess => ({
    type: CommunityTypes.GET_WARNINGS_SUCCESS,
    payload: warningList
})
export const updateWarning = (cdId: number, updateStatus: UpdateWarningRequest): UpdateWarnings => ({
    type: CommunityTypes.UPDATE_WARNINGS,
    payload: {cdId, updateStatus}
})
export const updateWarningSuccess = (result: boolean, msg: string): UpdateWarningsSuccess => ({
    type: CommunityTypes.UPDATE_WARNINGS_SUCCESS,
    payload: {result, msg}
})
export const preDeployComm = (cdId: number): PreDeployComm => ({
    type: CommunityTypes.PRE_DEPLOY_COMM,
    payload: cdId
})
export const preDeployCommSuccess = (warningList: any): PreDeployCommSuccess => ({
    type: CommunityTypes.PRE_DEPLOY_COMM_SUCCESS,
    payload: warningList
})
export const deployComm = (cdId: number): DeployComm => ({
    type: CommunityTypes.DEPLOY_COMM,
    payload: cdId
})
export const deployCommSuccess = (result: boolean, msg: string, response?: string): DeployCommSuccess => ({
    type: CommunityTypes.DEPLOY_COMM_SUCCESS,
    payload: {result, msg, response}
})
export const segmentTagging = (cdId: number, data: UpdateSegmentListRequest,getSegmentAdditionalData?: any): SegmentTagging => ({
    type: CommunityTypes.SEGMENT_TAGGING,
    payload: {cdId, data,getSegmentAdditionalData}
})
export const segmentTaggingSuccess = (result: boolean, msg: string, type: string): SegmentTaggingSuccess => ({
    type: CommunityTypes.SEGMENT_TAGGING_SUCCESS,
    payload: {result, msg, type}
})
export const segmentTaggingFail = (result: boolean, type: string): SegmentTaggingFail => ({
    type: CommunityTypes.SEGMENT_TAGGING_FAIL,
    payload: {result, type}
})
export const getSummaryTable = (cdId: number): GetSummaryTable => ({
    type: CommunityTypes.GET_SUMMARY_TABLE,
    payload: cdId
})
export const getSummaryTableSuccess = (data: string): GetSummaryTableSuccess => ({
    type: CommunityTypes.GET_SUMMARY_TABLE_SUCCESS,
    payload: data
})
export const getSegmentsFilters = (cdId: number, filterDefinitionId: number, segmentType: SegmentType): GetSegmentsFilters => ({
    type: CommunityTypes.GET_SEGMENTS_FILTERS,
    payload: {cdId, filterDefinitionId, segmentType}
})
export const getSegmentsFiltersSuccess = (filterList: string): GetSegmentsFiltersSuccess => ({
    type: CommunityTypes.GET_SEGMENTS_FILTERS_SUCCESS,
    payload: filterList
})
export const resetStore = (): ResetStore => ({
    type: CommunityTypes.RESET
})


