import appClient from "./configureAppClient";
import {
    AppFilter,
    AppMode,
    Body_login_for_access_token_token_login_post,
    CommunityType,
    CreateAppConfigRequest,
    CreateAppRequest,
    CreateConfigFromDefaultRequest,
    CreateConfigGroupRequest,
    CreateConfigRequest,
    CreateUserRequest,
    FilterDistinctRequest,
    FilterRequest,
    GroupMode,
    SegmentType,
    TagFilter,
    UpdateAnnotationHeaderRequest,
    UpdateAnnotationListRequest,
    UpdateAnnotationSegmentRequest,
    UpdateAppConfigRequest,
    UpdateAppRequest,
    UpdateCommDecisionListRequest,
    UpdateCommDetectionJobsRequest,
    UpdateConfigGroupRequest,
    UpdateConfigRequest,
    UpdateSegmentRequest,
    UpdateUserRequest,
    UpdateWarningRequest,
    UpsertJobSegmentsHeaderRequest
} from "../../AUTO_GENERATED_TYPES";

const {
    app: appRequests,
    configDefault: configRequests,
    configApp: appConfigRequests,
    configGroup: groupConfigRequests,
    auth: authRequests,
    user: userRequests,
    communityDetection: communityRequests,
    communityDeploy: communityDeploy,
    annotation: annotationRequests,
    configGeneral: configGeneralRequests,
} = appClient

//LOGIN
export const postLogin = (data: Body_login_for_access_token_token_login_post) => authRequests.loginForAccessTokenTokenLoginPost(data)

//USERS
export const getUsers = () => userRequests.getAllUsersV1UserAllGet()
export const newUser = (data: CreateUserRequest) => userRequests.createUserV1UserCreatePost(data)
export const updateUserData = (userId: number, data: UpdateUserRequest) => userRequests.updateUserV1UserUserIdUpdatePatch(userId, data)
export const deleteUserData = (userId: number) => userRequests.deleteUserV1UserUserIdDeleteDelete(userId)
export const resetPasswordData = (userId: number) => userRequests.resetPasswordV1UserUserIdPasswordResetPatch(userId)


//APPS
export const getApps = (mode: AppMode, filter?: AppFilter | undefined) => appRequests.getAllAppsV1AppAllGet(mode, filter)
export const createApp = (appData: CreateAppRequest) => appRequests.createAppV1AppPost(appData)
export const updateApp = (appData: UpdateAppRequest, id: number) => appRequests.updateAppV1AppAppIdPatch(id, appData)
export const deleteApp = (id: number) => appRequests.deleteAppV1AppAppIdDeleteDelete(id)
export const archiveApp = (id: number) => appRequests.archiveAppV1AppAppIdArchivePatch(id)
export const unArchiveApp = (id: number) => appRequests.unArchiveAppV1AppAppIdUnArchivePatch(id)
export const archiveAppList = (ids: string) => appRequests.archiveAppListV1AppArchiveListPatch(ids)
export const unArchiveAppList = (ids: string) => appRequests.unarchiveAppListV1AppUnarchiveListPatch(ids)
export const deleteAppList = (ids: string) => appRequests.deleteAppListV1AppDeleteListDelete(ids)
export const cloneApp = (id: number) => appRequests.cloneAppV1AppAppIdClonePost(id)
export const getAppsNames = (appMode: AppMode) => appRequests.getAllAppsNamesV1AppAllNamesGet(appMode)

//COMMUNITY
export const getCommunityAll = (appId?: number | undefined, appMode?: AppMode | undefined, archived?: boolean, limit?: number) => communityRequests.getCommunityDetectionJobsV1CommunityDetectionJobAllGet(appId, appMode, archived, limit)
export const getCommunityJob = (jobId: number) => communityRequests.getCommunityDetectionV1CommunityDetectionJobCdIdGet(jobId)
export const getCommunityJobComm = (jobId: number, type: CommunityType) => communityRequests.getJobCommunitiesV1CommunityDetectionJobCdIdCommunitiesGet(jobId, type)
export const getSegmentHeader = (cdId: number, segType: SegmentType) => communityRequests.getJobSegmentsHeaderV1CommunityDetectionJobCdIdSegmentsHeaderGet(cdId, segType)
export const getSegmentData = (cdId: number, segType: SegmentType, body: FilterRequest, tag: TagFilter, itemPp: number, page: number) => communityRequests.getJobSegmentsV1CommunityDetectionJobCdIdSegmentsPost(cdId, segType, body, tag, itemPp, page)
//@ts-ignore
export const patchDecisionComm = (cdId: number, data: UpdateCommDecisionListRequest) => communityRequests.updateShareCommunitiesV1CommunityDetectionCdIdCommunitySharePatch(cdId, data)
export const patchCommDetails = (cdId: number, data: UpdateCommDetectionJobsRequest) => communityRequests.updateCommunityDetectionJobV1CommunityDetectionCdIdPatch(cdId, data)
//@ts-ignore
export const patchSegDetails = (cdId: number, data: UpsertJobSegmentsHeaderRequest) => communityRequests.upsertJobSegmentsHeaderV1CommunityDetectionJobCdIdSegmentsHeaderUpsertPost(cdId, data)
export const getWarningsList = (cdId: number) => communityRequests.getNewWarningsV1CommunityDetectionCdIdWarningsGet(cdId)
//@ts-ignore
export const updateWarnings = (cdId: number, updateStatus: UpdateWarningRequest) => communityRequests.updateWarningsV1CommunityDetectionCdIdWarningsUpdatePatch(cdId, updateStatus)
export const preDeployCommunity = (cdId: number) => communityDeploy.preDeployCdV1CommunityDeployCdIdPreGet(cdId)
export const deployCommunity = (cdId: number) => communityDeploy.deployCdV1CommunityDeployCdIdPost(cdId)
//@ts-ignore
export const segmentTagging = (cdId: number, data: UpdateSegmentRequest) => communityRequests.updateSegmentsV1CommunityDetectionCdIdSegmentsUpdatePatch(cdId, data)
export const getSummaryTable = (cdId: number) => communityRequests.getJobCommunitiesSummaryV1CommunityDetectionJobCdIdCommunitiesSummaryGet(cdId)
export const getSegmentFilters = (cdId: number, filterDefinitionId: number, segmentType: SegmentType, requestBody: FilterDistinctRequest, tagFilter: TagFilter) => communityRequests.getSegmentsFiltersV1CommunityDetectionJobCdIdSegmentsFilterDefinitionIdFiltersPost(cdId, filterDefinitionId, segmentType, requestBody, tagFilter)
export const getFilterList = (cdId: number, segmentType: SegmentType) => communityRequests.getSegmentsFilterDefinitionV1CommunityDetectionJobCdIdSegmentsFilterDefinitionGet(cdId, segmentType)
export const getOrderDefinitions = (cdId: number, segmentType: SegmentType) => communityRequests.getSegmentsOrderDefinitionV1CommunityDetectionJobCdIdSegmentsOrderDefinitionGet(cdId, segmentType)
export const getDeploymentHistory = (appId?: number | undefined) => communityDeploy.getDeploymentHistoryV1CommunityDeployAllGet(appId)
export const getCDPlotNames = (cdId: number)=> communityRequests.getPlotNamesV1CommunityDetectionJobCdIdPlotNamesGet(cdId)

//CONFIG
export const getConfigDefault = (groups?: string) => configRequests.getDefaultsConfigsV1ConfigDefaultAllGet(groups)
export const getGroupsDefault = (groupMode?: GroupMode | undefined, archived?: boolean, appRelated?: boolean) => groupConfigRequests.getGroupsWithFiltersV1ConfigGroupAllFilterGet(groupMode, archived, appRelated)
export const createNewConfig = (configData: CreateConfigRequest) => configRequests.createDefaultConfigV1ConfigDefaultPost(configData)
export const createNewConfigGroup = (configGroupData: CreateConfigGroupRequest) => groupConfigRequests.createGroupV1ConfigGroupCreatePost(configGroupData)
export const editConfig = (configData: UpdateConfigRequest, configId: number) => configRequests.updateDefaultConfigV1ConfigDefaultConfigIdPatch(configId, configData)
export const editConfigGroup = (configGroupData: UpdateConfigGroupRequest, groupId: number) => groupConfigRequests.updateGroupV1ConfigGroupGroupIdUpdatePatch(groupId, configGroupData)
export const deleteConfig = (configId: number) => configRequests.deleteDefaultConfigV1ConfigDefaultConfigIdDelete(configId)
export const deleteConfigGroup = (groupId: number) => groupConfigRequests.deleteGroupV1ConfigGroupGroupIdDeleteDelete(groupId)
export const changeConfigGroupArchive = (groupId: number, archived: boolean) => groupConfigRequests.archiveGroupV1ConfigGroupGroupIdArchivePatch(groupId, {archived})
// export const rollBackAppConfig = (configHistId: number) => configRequests.rollbackAppConfigV1ConfigAppConfigHistIdRollbackPatch(configHistId)
// export const getAppConfig = (appToken: string) => configRequests.getAppConfigsForDashV1ConfigAppAppTokenDashGet(appToken)
// export const updateAppConfig = (configData: any, appToken: string) => configRequests.createOrUpdateAppConfigV1ConfigAppAppTokenPatch(appToken, configData)
// export const deleteAppConfig = (configId: number, appToken: string) => configRequests.deleteAppConfigV1ConfigAppAppTokenConfigIdDelete(appToken, configId)
export const createAppConfig = (requestBody: CreateAppConfigRequest) => appConfigRequests.createAppConfigV1ConfigAppCreatePost(requestBody)
export const createAppConfigFromDefault = (requestBody: CreateConfigFromDefaultRequest) => appConfigRequests.createAppConfigFromDefaultV1ConfigAppCreateFromDefaultPost(requestBody)
export const deleteAppConfig = (appConfigId: number) => appConfigRequests.deleteAppConfigV1ConfigAppAppConfigIdDeleteDelete(appConfigId)
export const getAppConfig = (groups?: string | undefined, appId?: number | undefined, showOnlyApp?: boolean) => appConfigRequests.getAllAppsConfigsV1ConfigAppAllGet(groups, appId, showOnlyApp)
export const updateAppConfig = (appConfigId: number, requestBody: UpdateAppConfigRequest) => appConfigRequests.updateAppConfigV1ConfigAppAppConfigIdUpdatePatch(appConfigId, requestBody)
export const getDefaultAudits = (configId: number) => configRequests.getDefaultConfigAuditV1ConfigDefaultConfigIdAuditGet(configId)
export const getAppAudits = (appConfigId: number) => appConfigRequests.getAppConfigAuditV1ConfigAppAppConfigIdAuditGet(appConfigId)
export const getAllAudits = (groupMode: GroupMode, groups?: string | undefined, appId?: number | undefined, configName?: string | undefined) => configGeneralRequests.getAuditForAllConfigsV1ConfigAuditAllGet(groupMode, groups, appId, configName)
export const getAuditsGroups = (groupMode?: GroupMode | undefined) => groupConfigRequests.getAllGroupsForAuditV1ConfigGroupAllAuditGet(groupMode)

//Annotations
export const getAnnotationJobs = (appMode?: AppMode, archived: boolean = false, offset?: number, sizePerPage?: number, searchTerm?: string) => {
    return annotationRequests.getAnnotationJobsV1AnnotationJobAllGet(appMode, archived, offset, sizePerPage, searchTerm);
};
export const getAnnotationJobHeader = (annotationId: number) => annotationRequests.getJobSegmentsHeaderV1AnnotationJobAnnotationIdSegmentsHeaderGet(annotationId)
export const getAnnotationJobData = (annotationId: number, requestBody: FilterRequest, tagFilter?: string | undefined, itemsPerPage?: number, page?: number) => {
    const finalTagFilter = tagFilter ?? undefined
    return annotationRequests.getJobSegmentsV1AnnotationJobAnnotationIdSegmentsPost(annotationId, requestBody, finalTagFilter, itemsPerPage, page)
}
export const updateAnnotationJobHeader = ({
                                              annotationId,
                                              requestBody
                                          }: { annotationId: number, requestBody: UpdateAnnotationHeaderRequest }) => annotationRequests.updateJobSegmentsHeaderV1AnnotationJobAnnotationIdSegmentsHeaderUpdatePatch(annotationId, requestBody)
export const getAnnotationClasses = (annotationId: number) => annotationRequests.getAnnotationClassesV1AnnotationJobAnnotationIdClassesGet(annotationId)
export const updateAnnotationTag = (annotationId: number, requestBody: UpdateAnnotationListRequest) => annotationRequests.updateSegmentsAnnotationV1AnnotationJobAnnotationIdSegmentsUpdatePatch(annotationId, requestBody)
export const updateAnnotationSegmentComment = (annotationId: number, asId: number, requestBody: UpdateAnnotationSegmentRequest) => annotationRequests.updateAnnotationSegmentV1AnnotationJobAnnotationIdSegmentsAsIdUpdatePatch(annotationId, asId, requestBody)
export const getAnnotationFiltersDefinition = (annotationId: number) => annotationRequests.getSegmentsFilterDefinitionV1AnnotationJobAnnotationIdSegmentsFilterDefinitionGet(annotationId)
export const getAnnotationFilters = (annotationId: number, filterDefinitionId: number, requestBody: FilterDistinctRequest, tagFilter: string) => annotationRequests.getSegmentsFiltersV1AnnotationJobAnnotationIdSegmentsFilterDefinitionIdFiltersPost(annotationId, filterDefinitionId, requestBody, tagFilter)
export const getAnnotationOrderDefinition = (annotationId: number) => annotationRequests.getSegmentsOrderDefinitionV1AnnotationJobAnnotationIdSegmentsOrderDefinitionGet(annotationId)
export const getAnnotationPlotNames = (annotationId: number) => annotationRequests.getPlotNamesV1AnnotationJobAnnotationIdPlotNamesGet(annotationId)

//Plots
// export const getPlotsNames = (plotType: PlotType) => configGeneralRequests.getPlotNamesV1ConfigPlotNamesGet(plotType)
